import React, { useState } from "react";
import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider"
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import BackButton from './backButton.js';
import NFTCard from './NFTCard'
import { createAlchemyWeb3 } from '@alch/alchemy-web3';

const WalletApp = ( {backButton} ) => {
  const networkID = 1;
  const wssURL = `wss://eth-goerli.g.alchemy.com/v2/ipdcvY7_FeHp6R3JFfwqCBlaNvPo3Umc`;
  const httpsURL = "https://eth-goerli.g.alchemy.com/v2/ipdcvY7_FeHp6R3JFfwqCBlaNvPo3Umc";
  const [applicationError,setApplicationError] = useState(false)
  const [chainError,setChainError] = useState(false)
  const [walletA, setWalletA] = useState(null) //42 CHAR WALLET ADDRESS
  const [userWeb3, setUserWeb3] = useState()
  const [fetched, setFetched] = useState(true)
  const [nftR, setNftsR] = useState([])
  const [bigArray, setBigArray] = useState([[],[]])
  const [rideData, setRideData] = useState([]) //Chosen Ride DATA
  const [darkMode, setDarkMode] = useState(false)
  const [collectionState, setCollectionState] = useState("aperidersCollection")

  const alchemyWeb3 = createAlchemyWeb3('https://eth-mainnet.g.alchemy.com/v2/FeBF4O8PdhwNkQFNYAnjpWkjBuq74TMQ');

  const contractAddresses = [
      '0xa08b319f0f09ae8261db2034d43bf40c673f0ad0',
      '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
      '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
      '0x22c08c358f62f35b742d023bf2faf67e30e5376e',
      '0xae63b956f7c77fba04e2eea59f7b8b2280f55431',
      '0x4b103d07c18798365946e76845edc6b565779402',
      '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e',
      '0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5',
      '0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa',
      '0x2d0d57d004f82e9f4471caa8b9f8b1965a814154',
      '0x2120d19431e0dd49411e5412629f8e41a72cfabd',
      '0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d',
      '0xe83dd605b70b47c8af86580bdd4fcb987ff36e60',
      '0x87212aa99f65611f6d67e0fbad76d06478753704']
                              //Array of addresses of supported ape collections

  const contractNames = ['TRAC','BAYC','MAYC','0xAPE','0xMAYC','GACC','DAW','SAC','LAYC','PAYC','JBAS','ARC','BTFD','BADDIE']

    const connectWallet = () => {
    if(typeof window.ethereum == 'undefined') {
      document.getElementById("metamask").className="walletError"
    }
    document.getElementById("walletPopUp").style.transform = "scale(1)"
    document.getElementById("walletPopUp").style.display = "flex"
  }
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

  const goToGarage = () => {
    backButton()
  }

  const connectWC = async () => {
    setFetched(true)
    
    const provider = new WalletConnectProvider({ //Wallet Connect Provider object set up with apps infura id
      rpc: { networkID: httpsURL}
    });

    provider.walletConnectProvider = undefined;
      await provider.enable().catch((error) => {
        if (error) {
          console.log("Modal Closed")
          return
        }
      })
            setFetched(false)

    const web3 = new Web3(provider)

      let chainID = await web3.eth.getChainId()
      console.log(chainID)

      if (chainID !== networkID){
        setChainError(true)
        await new Promise(r => setTimeout(r, 5000));
        setChainError(false)
        return
      }

      provider.on("chainChanged", chainId => {
        if (chainId !== networkID){
          setApplicationError("Application Error: Chain changed, please change back to Ethereum to continue")
        } else {
          setApplicationError(false)
        }
      });

      provider.on("accountsChanged", accounts => {
        setWalletA(accounts[0])
      });

      const accounts = await web3.eth.getAccounts();


      await getUsersRides(accounts[0])
      setWalletA(accounts[0])
      setUserWeb3(web3)
      await delay(300)
      document.getElementById("walletPopUp").style.transform = "scale(0)"
          document.getElementById("aperiders").style.justifyContent = "center"
          document.getElementById("aperidersCollection").disabled = true;

      return

  }

  const connectMM = async () => {
    setFetched(true)
    if (typeof window.ethereum !== 'undefined') {

      const chainID= await window.ethereum.request({ method: 'eth_chainId' });
      console.log("CHAIN ID: "+chainID.slice(2))
      if ((chainID.slice(2)) !== networkID.toString()){
        setChainError(true)
        await new Promise(r => setTimeout(r, 5000));
        setChainError(false)
        return
      }
            

      const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
      setFetched(false)

      const web3 = new Web3(window.ethereum)

      window.ethereum.on('chainChanged', chainId => {
        setApplicationError(false)
        if ((chainID.slice(2)) !== networkID){
          setApplicationError("Application Error: Chain changed, please refresh to restart")
        } else {
          setApplicationError(false)
        }
      });

      window.ethereum.on('accountsChanged', (accounts) => {
        setWalletA(accounts[0])
      });


      await getUsersRides(accounts[0])
      setWalletA(accounts[0])
      setUserWeb3(web3)
      await delay(300)
      document.getElementById("walletPopUp").style.transform = "scale(0)"
      document.getElementById("aperidersCollection").disabled = true;
      return

    } else {
      return
    }
  }

  const connectCBW = async () => {

    setFetched(true)

    const coinbaseWallet = new CoinbaseWalletSDK({
      appName: `Ape Rides`,
      appLogoUrl: `https://garage.aperides.io/logo192.png`,
      darkMode: true
    })

    const ethereum = coinbaseWallet.makeWeb3Provider(httpsURL, networkID)
          setFetched(false)

    const web3 = new Web3(ethereum)

    await ethereum.enable().then((accounts) => {
  console.log(`User's address is ${accounts[0]}`)
  web3.eth.defaultAccount = accounts[0]
})

      const accounts = await web3.eth.getAccounts();

      await getUsersRides(accounts[0])
      setWalletA(accounts[0])
      setUserWeb3(web3)
      await delay(300)
      document.getElementById("walletPopUp").style.transform = "scale(0)"
      document.getElementById("aperidersCollection").disabled = true;
      return

  }

  const getRideMeta = async (tokenID) => {
    console.log(tokenID)
    let data = await fetch('https://app.aperides.io/api/getAORMetadata/'+tokenID).then((response) => response.json())
    return data

  }

  const getARCMeta = async (tokenID) => {
    console.log(tokenID)
    let data = await fetch('https://app.aperides.io/api/getARCMetadata/'+tokenID).then((response) => response.json())
    return data

  }

  const getRideBackground = async (tokenID) => {

    let data = await fetch('https://app.aperides.io/api/getApeRiderBackground/'+tokenID).then((response) => response.json())
    return data

  }

  const getImage = async (filepath) => {

    let data = await fetch('https://app.aperides.io/api/getLayerURL/'+filepath).then((response) => response.json()).catch((error) => {
        console.log(error)
    })
    return data[0]

}

const getMetadataTrait = (data,_trait,lowercase=true) => {
  let _value = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === _trait) {
        if (lowercase) {
      _value = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
        } else {
            _value = atr.value
        }
    }
    } catch {
      if (atr.trait_type.toLowerCase() === _trait) {
          if (lowercase) {
      _value = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
      } else {
          _value = atr.value
      }
    }
    }
  })
    
  return _value
}

  const getRideMetadata = async (rideIDArray) => {
    let tempridearray = []
    let bigRideArray = [[],[]]
    for (const rideId of rideIDArray) {
        console.log(rideId.contract.address.toLowerCase())
        console.log(rideId)
        console.log(rideIDArray[rideId])
        let rideToken = parseInt(rideId.id.tokenId)

        if (rideId.contract.address.toLowerCase()==="0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d") {
          let data = await getARCMeta(rideToken)
          let bgCol = await getMetadataTrait(data,"background");
          let testdata = {contract: '0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d', tokenId: rideToken, name: `APE RIDER CLUB #${rideToken}`, url: `https://storage.googleapis.com/aperidersclub/revealed/images/${rideToken}.png`, ride: 'arc', background: `arc/bg/${bgCol.toLowerCase()}.png`}
            bigRideArray[1].push(testdata)
            console.log("pushed to ride array")

        } else {
          let data = await getRideMeta(rideToken)
          console.log(data)
          
          let occupied = await getMetadataTrait(data, "active", false)
          let rideType = "undefined"

          if (occupied.toLowerCase()=="yes") {
          
            let testdata = {contract: '0x380f0656a2cc032583bd26d4ffe29a27b6079050', tokenId: rideToken, name: `AR #${rideToken} (${rideType})`, url: `https://storage.googleapis.com/aperides/photobook/occupied/image/${rideToken}.png`, ride: rideType.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')}
            tempridearray.push(testdata)
            bigRideArray[0].push(testdata)
            console.log("pushed to ride array")

          } else {
            console.log("ride not occupied")
          }
        }
        
    }
    setNftsR(tempridearray)
    setBigArray(bigRideArray)
    console.log(tempridearray)
  }
    
  const getUsersRides = async (_owner) => {
      let response = await alchemyWeb3.alchemy.getNfts({owner: _owner, contractAddresses: ["0x380f0656a2cc032583bd26d4ffe29a27b6079050","0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d"], withMetadata: false})
      console.log(response)
      await getRideMetadata(response.ownedNfts);
      
  }

  const generateBanner = async (nft) => {

    let ctx = document.getElementById('BANNER').getContext("2d")

        document.getElementById("bannerMain").style.display = "flex";
    document.getElementById("bannerHider").style.display = "flex";
    document.getElementById('bannerMain').scrollIntoView({behavior: 'smooth'});

    await generate(nft, ctx)

  }

  const drawTextToCanvas = (settings, context, text) => {
    //settings: align, weight, size, font, color, x pos, y pos, opacity**. All except ** must be provided
          settings = settings.split(" ")
          context.textAlign = settings[0]
          context.font = settings[1]+" "+settings[2]+" "+settings[3]
          if (settings[4]==="user"){
            context.fillStyle = 'white' //add user colour here
          } else {
            context.fillStyle = settings[4]
          }
          if (settings.length>6){
            context.globalAlpha = settings[7]
            context.fillText(text, settings[5], settings[6])
            context.globalAlpha = 1
            
          } else {
            context.fillText(text, settings[5], settings[6])
          }
  }

  const generate = async (nft, ctx, drawColor='white') => {

    if (darkMode) {
      drawColor = 'black'
    }

    await ctx.clearRect(0,0,3000,3000)
    let img = new Image()
    let bg;
    let apeOnRideInfo;
    if (collectionState==="aperidersCollection") {

      apeOnRideInfo = await getRideBackground(nft.tokenId)

      if (apeOnRideInfo.bg.length>1) {
        console.log("lambo");
        bg = apeOnRideInfo.bg[0]
        img.src = bg[0];
      }

      console.log(apeOnRideInfo)
      console.log(contractNames[contractAddresses.indexOf(apeOnRideInfo.address.toLowerCase())])
      console.log(apeOnRideInfo.id)
      console.log(apeOnRideInfo.rideId)
      bg = apeOnRideInfo.bg
      img.src = bg[0];
    } else {
      img.src = await getImage(nft.background)
    }

    img.onload = async () => {
      await ctx.drawImage(img,0,-4000,4500,4500)
      console.log("drawn img1")
      let img2 = new Image()
      if (apeOnRideInfo.bg.length>1) {
        img2.src = apeOnRideInfo.bg[1]
      } else {
        img2.src = nft.url
      }
      console.log(nft.url)
      img2.onload = async () => {
        if (apeOnRideInfo.bg.length>1) {
          await ctx.drawImage(img2,800,90,200,200)
        } else {
          await ctx.drawImage(img2,1000,0,500,500)
        }
        console.log("drawn img2")
      let img3 = new Image()
      if (apeOnRideInfo.bg.length>1) {
        img3.src = apeOnRideInfo.bg[2]
      } else if (darkMode) {
        img3.src = './aperidestext.png'
      } else {
        img3.src = './bannertxt.png'
      }
      
      img3.onload = async () => {
        if (apeOnRideInfo.bg.length>1) {
          await ctx.drawImage(img3,500,-50,1000,563)
          let img4 = new Image()
          if (darkMode) {
            img4.src = './aperidestext.png'
          } else {
            img4.src = './bannertxt.png'
          }
          img4.onload = async () => {
            await ctx.drawImage(img4,20,20,683.25/2,252.375/2)
                      let textToDraw = `${contractNames[contractAddresses.indexOf(apeOnRideInfo.address.toLowerCase())]} #${apeOnRideInfo.id} on RIDE #${apeOnRideInfo.rideId}`
        await drawTextToCanvas(`left 900 38px Raleway ${drawColor} 30 160`,ctx, textToDraw);
                  document.getElementById("bannerHider").style.display = "none";
          }

        } else {
          await ctx.drawImage(img3,300,93.8125,683.25,252.375);
                  let textToDraw;
        if (collectionState==="arcsCollection") {
          textToDraw = nft.name
        } else {
          textToDraw = `${contractNames[contractAddresses.indexOf(apeOnRideInfo.address.toLowerCase())]} #${apeOnRideInfo.id} on RIDE #${apeOnRideInfo.rideId}`
        }
        await drawTextToCanvas(`center 900 38px Raleway ${drawColor} 635 350`,ctx, textToDraw);
        document.getElementById("bannerHider").style.display = "none";
        }
        console.log("drawn img3")
      }
      }
    }
    



  

}

const handleCollectionChange = async () => {
    console.log("click 1")
    document.getElementById("walletPopUp").style.transform = "scale(1)"
    await delay(400)
    setNftsR([])
    document.getElementById("aperidersCollection").disabled = false;
    document.getElementById("arcsCollection").disabled = true;
    setCollectionState("arcsCollection")
    setNftsR(bigArray[1])
    await delay(1000)
    document.getElementById("walletPopUp").style.transform = "scale(0)"
}
const handleCollectionChange2 = async () => {
    console.log("click 2")
    document.getElementById("walletPopUp").style.transform = "scale(1)"
    await delay(400)
    setNftsR([])
    document.getElementById("arcsCollection").disabled = false;
    document.getElementById("aperidersCollection").disabled = true;
    setCollectionState("aperidersCollection")
    setNftsR(bigArray[0])
    await delay(1000)
    document.getElementById("walletPopUp").style.transform = "scale(0)"
}

const downloadBanner = () => {
  var canvas = document.getElementById("BANNER");
  let image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
  var link = document.createElement('a');
  link.download = "my-image.png";
  link.href = image;
  link.click();
}

  return (
    <>
    { applicationError ?
    <div className="appErrorWrapper">
      <div className="appErrorPopUp">
        <p className="sm-txt col-white pd04 txt-align-c">{applicationError}</p>
      </div>
    </div>
    :
    <>
    </>
  }
  


  <div id="walletPopUp" className="walletPopUpNone">
    <p className="sm-txt sdark-txt">{ fetched ? "Choose your preferred wallet" : "Loading" }</p>
    { chainError ? <p className="xs-txt red-txt">Wrong Network - Please change to Ethereum</p> : <p className="none"></p>}
    { fetched ?
    <div className="flex walletFlex">
      <div onClick={connectMM} id="metamask" className="flexc wallet">
        <img src="metamask.png" alt="metamask" className="walletIcon"></img>
        <p className="xs-txt sdark-txt mgt">Metamask</p>
      </div>
       <div onClick={connectWC} className="flexc wallet">
         <img src="walletconnect.png" alt="walletconnect" className="walletIcon"></img>
        <p className="xs-txt sdark-txt mgt">Wallet Connect</p>
      </div>
        <div onClick={connectCBW} className="flex-center flex-col wallet">
            <img src="coinbasewallet.svg" alt="coinbasewallet" className="walletIcon"></img>
            <p className="xs-txt sdark-txt">Coinbase Wallet</p>
        </div>
    </div>
    :
    <>
    <div className="c-loader"></div>
    <p className="sdark-txt xs-txt mgt">This may take a second if you have a lot of NFTs</p>
    </>
  }

  </div>

  <div id="header" className="header">
        <div className="headerItem flexStart">
          <p className="xs-txt white-txt mgl" onClick={goToGarage}>Back</p>
        </div>
              
        <div className="flex headerItem" id="aperidesTextLogo">
          <img src="bannertxt.png" alt="Ape Rides logo" height="35px" className="op9"></img>
        </div>
          
        <div className="headerItem flexEnd">
            { walletA ?
            <button onClick={connectWallet}>{'0x'+walletA.slice(2,7).toUpperCase()+'..'+walletA.slice(35).toUpperCase()}</button>
            :
            <button onClick={connectWallet}>CONNECT WALLET</button>
            }
        </div>

  </div>

  <div className="flexc bannerMain">
    <p className="sm-txt white-txt expandHeader">
      Welcome to the Ape Rides Banner Generator
    </p>
    { !walletA ?
      <p className="sm-txt white-txt mgb50">
        Connect a wallet to get started
      </p>
      :
      <>
      </>
    }

    { walletA ?
    <div className="flexc rides">
      <div className="flex">
        <button id="aperidersCollection" onClick={handleCollectionChange2}>ApeRiders</button>
        <button id="arcsCollection" onClick={handleCollectionChange}>ApeRiderClubs</button>
      </div>
      <p className="sm-txt white-txt mgt50 mgb">Choose an Ape Rider</p>
      <div className="flex">
        <p className="xs-txt white-txt">Dark Mode</p>
        <input checked={darkMode} onChange={()=>setDarkMode(!darkMode)} type="checkbox" className="safemintbox"></input>
      </div>
      <div id="aperiders" className="flex fwrap scroll scrollsmall">
        {nftR.map((nft, index) => {
          return <NFTCard apeCallback={generateBanner} nft={nft} key={index} />
        })}
      </div>
    </div>
    :
    <></>
  }
  <div id="bannerMain" className="bannercMain">
        <div id="bannerHider" className="bannerHider"><p className="md-txt white-txt mgb">Your Banner is been Generated</p><div className="c-loader mgt"></div></div>
      <canvas id="BANNER" className="bannerCanvas mgt mgb10" width="1500" height="500">
        Your browser does not support HTML5 canvas
      </canvas>
      </div>

  </div>



  </>
    );
}

class Wallet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {window: props.window, setWindow: props.setWindow};
    this.backButton = this.backButton.bind(this);
  }



  backButton() {
    this.state.setWindow("Explore")
  }

  render() {
    if (this.state.window==="Wallet") {
    return(
      <>
      <WalletApp backButton={this.backButton}/>
      </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }


}

export default Wallet
