//APERIDES EXPLORE DAPP

//IMPORTS
import React from "react"
import Explore from './Explore.js'
import Mint from './Mint.js'
import Garage from './GarageApp3.js'
import Wallet from './Wallet.js'



class App extends React.Component {

  state = { currentWindow: "Explore" }

  constructor(props) {
    super(props);
    this.setCurrentWindow = this.setCurrentWindow.bind(this)

  }

  async setCurrentWindow(cWindow) {
    await this.setState({
      currentWindow: cWindow
    });
     console.log("State set")
     console.log(this.state.currentWindow)
  }

//RETURN
  
  render(){

    switch (this.state.currentWindow) {
      case "Explore":
        return (
          <>
            <Explore window={this.state.currentWindow} setWindow={this.setCurrentWindow} />
          </>
        );
      case "Wallet":
        return (
          <>
            <Wallet window={this.state.currentWindow} setWindow={this.setCurrentWindow} />
          </>
        );
      case "Mint":
        return (
          <>
            <Mint window={this.state.currentWindow} setWindow={this.setCurrentWindow} />
          </>
        );
      case "Garage":
        return (
          <>
            <Garage window={this.state.currentWindow} setWindow={this.setCurrentWindow} />
          </>
        );
      default:
        return (
          <>
          </>
        );
    }
  }

}

export default App;
