import React from 'react'

class ExploreButton extends React.Component {

  constructor(props) {
    super(props)
    this.state = {icon: props.icon, text: props.text, linkType: props.linkType, link: props.link, setWindow: props.setWindow, color: props.color}
  }

  goToLink() {
    if (this.state.linkType === "state") {
      this.state.setWindow(this.state.link)
    } else if (this.state.linkType === "disabled") {
      return
    } else {
      window.open(this.state.link, '_blank')
    }
  }

  render(){
    return (
    <div className="exploreButtonContainer" style={{backgroundColor: this.state.color}} onClick={() => this.goToLink()}>
          <img src={this.state.icon} className="exploreButtonIcon"></img>
          <p className="sm-txt white-txt mgz">{this.state.text}</p>
    </div>
    )
  }
}

class Explore extends React.Component {

  constructor(props) {
    super(props);
    this.state = {window: props.window, setWindow: props.setWindow};
    this.wallet = this.wallet.bind(this);
    this.mint = this.mint.bind(this);
    this.garage = this.garage.bind(this);

  }

  learnMore() {
    window.location.replace("./#info")
  }
  roadmap() {
    window.location.replace("./#roadmap")
  }
  fandq() {
    window.location.replace("./#f&q")
  }
  garage() {
    this.state.setWindow("Garage")
  }
  aperidesio() {
    window.open("https://aperides.io", '_blank')
  }
  wallet() {
    this.state.setWindow("Wallet")
    console.log("Wallet button")
  }
  mint() {
      window.open("https://mint.aperides.io", '_blank')
  }


//RETURN
  render() {
    if (this.state.window==="Explore") {
  return (
    <div className="flexContainer alignCenter junglebg">
  <div className="header flexCenter posRelative">

    <img src="bannertxt.png" alt="Ape Rides logo" height="35px" className="op9"></img>

  </div>

  <div className="App posRelative">

    <ExploreButton icon="./aperides.png" text="aperides.io" linkType="external" link="https://www.aperides.io" setWindow={this.state.setWindow} />

    <ExploreButton icon="./aperidesmint.png" text="Mint a Ride" linkType="external" link="https://mint.aperides.io" setWindow={this.state.setWindow} />

    <ExploreButton icon="./garageicon.png" text="Garage" linkType="state" link="Garage" setWindow={this.state.setWindow} />

    <ExploreButton icon="./banner.png" text="Banner" linkType="state" link="Wallet" setWindow={this.state.setWindow} />

    <ExploreButton icon="./twitterlogo.png" text="twitter" linkType="external" link="https://www.twitter.com/aperidesnft" setWindow={this.state.setWindow} color="#1D9BF0" />

    <ExploreButton icon="./discordlogo.png" text="discord" linkType="external" link="https://discord.gg/aperides" setWindow={this.state.setWindow} color="#5865F2" />

    <ExploreButton icon="./openseaicon.png" text="Rides" linkType="external" link="https://opensea.io/collection/aperides" setWindow={this.state.setWindow} color="#1868B7" />

    <ExploreButton icon="./openseaicon.png" text="Ape Riders" linkType="external" link="https://opensea.io/collection/aperiders" setWindow={this.state.setWindow} color="#1868B7" />

    <ExploreButton icon="./openseaicon.png" text="Ape Riders Club" linkType="external" link="https://opensea.io/collection/ape-riders-club" setWindow={this.state.setWindow} color="#1868B7" />
    
    <ExploreButton icon="./etherscan.png" text="Rides" linkType="external" link="https://etherscan.io/address/0x1748b24faf9780b74e5a9f3feb41553e712e94aa" setWindow={this.state.setWindow} color="#21325B" />

    <ExploreButton icon="./etherscan.png" text="Ape Riders" linkType="external" link="https://etherscan.io/address/0x380f0656a2cc032583bd26d4ffe29a27b6079050" setWindow={this.state.setWindow} color="#21325B" />

    <ExploreButton icon="./etherscan.png" text="Ape Riders Club" linkType="external" link="https://etherscan.io/address/0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d" setWindow={this.state.setWindow} color="#21325B" />
  </div>

</div>
);
} else {
  return (
    <>
    </>
  );
}
}
}

export default Explore
